/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap');

/* Core Grid CSS */
@import 'ag-grid-community/styles/ag-grid.css';
/* Quartz Theme Specific CSS */
@import 'ag-grid-community/styles/ag-theme-quartz.css';

//common button style 
.contained_btn {
    background-color: #FF6B00 !important;
    border-radius: 8px !important;
    padding: 12px 21px !important;
    color: #FFF !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: .2s all ease-in-out;
    height: auto !important;
    min-width: auto !important;
    min-height: 48px !important;
    max-height: 100% !important;
    box-shadow: none !important;


    &:hover {
        background-color: #FF974D !important;
    }

}

.outline_btn {
    background-color: transparent;
    border-radius: 8px !important;
    padding: 11px 19px !important;
    color: #FF6B00 !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #FF6B00 !important;
    transition: .2s all ease-in-out;
    height: auto !important;
    min-width: auto !important;
    min-height: 48px !important;
    max-height: 100% !important;
    box-shadow: none !important;

    &:hover {
        background-color: #FF974D !important;
        color: #FFF !important;
        border-color: #FF974D !important;

        i {
            svg {
                fill: #FFF !important;
            }
        }
    }

    i {
        display: inline-block;
        line-height: 0;

        &.startIcon {
            margin-right: 10px;

        }

        &.endIcon {
            margin-left: 10px;

        }
    }

}

.viewmore_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 1.4 !important;
    color: #2D2D2D;
    transition: all .3s ease-in-out;
    height: auto !important;
    min-width: auto !important;
    min-height: auto !important;
    max-height: 100% !important;
    box-shadow: none !important;
    
        svg {
            path {
                fill: #2D2D2D !important;
            }
        }
    
    &:hover {
        transform: scale(1.1);
        opacity: .8;
    }
    .mdc-button__label{
        margin-right: 4px;
    }
}

// common dialog style
.app-paperWrap {
    .mat-mdc-dialog-container {
        .mdc-dialog__container {
            .mat-mdc-dialog-surface {
                padding: 0;
                box-shadow: none;
                border-radius: 8px !important;
                overflow: hidden;

                &::before {
                    display: none;
                }
            }
        }
    }
}

// Overwriting Fuse error alert
.errorNoACB {
    .fuse-alert-content {
        .fuse-alert-message {
            margin-left: 0 !important;
        }
    }
}

// common calender Style
.calendersection {
    .mat-calendar {
        .ng-star-inserted {
            .mat-calendar-header {
                padding: 16px 16px 12px;

                .mat-calendar-controls {
                    margin: 0;
                    justify-content: space-between;

                    .mdc-icon-button {
                        width: 20px !important;
                        height: 20px;
                        min-width: 20px;
                        min-height: 20px;

                        &::after {
                            display: none;
                        }

                        &::before {
                            content: ">";
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: cover;
                            width: 15px;
                            height: 15px;

                        }
                    }

                    .mat-calendar-previous-button {
                        order: 0;

                    }

                    .mat-calendar-period-button {
                        order: 1;
                        margin: 0;
                        padding: 0 10px !important;
                        min-width: auto;
                        height: auto;
                        width: calc(100% - 40px);
                        flex-basis: calc(100% - 40px);
                        min-height: auto;

                        &:hover {
                            background-color: transparent;
                        }

                        .mdc-button__label {
                            color: #2D2D2D;
                            font-weight: 700;
                            font-family: "Archivo", sans-serif;
                            font-size: 12px;
                        }
                    }

                    .mat-calendar-next-button {
                        order: 3;
                    }
                }
            }
        }

        .mat-calendar-content {
            padding: 0 16px 16px;

            .ng-star-inserted {
                .mat-calendar-table {
                    thead {
                        tr {
                            th {
                                color: #737373;
                                font-weight: 700;
                                font-family: "Archivo", sans-serif;
                                font-size: 12px;
                                padding: 8px 0;
                                line-height: 1.3;

                                &.mat-calendar-table-header-divider {
                                    padding: 0;

                                    &::after {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }

                    .mat-calendar-body {
                        tr {
                            &.ng-star-inserted {
                                .mat-calendar-body-label {
                                    visibility: hidden;
                                }
                            }

                            td {
                                color: #2D2D2D;
                                font-weight: 700;
                                font-family: "Archivo", sans-serif;
                                font-size: 12px;

                                button {
                                    .mat-calendar-body-cell-content {
                                        border-radius: 4px;
                                        transition: .4s ease-in-out;
                                        overflow: hidden;
                                        position: relative;
                                    }

                                    .mat-calendar-body-today {
                                        border-color: transparent;
                                    }

                                    &.special-date {

                                        .mat-calendar-body-cell-content {
                                            border-radius: 4px;
                                            background-color: #FFF0E6;
                                            color: #FF6B00;
                                        }

                                    }

                                    &.mat-calendar-body-cell {

                                        &.mat-calendar-body-active {

                                            .mat-calendar-body-selected {
                                                background-color: #FF6B00 !important;
                                                color: #FFF !important;
                                                border-radius: 4px !important;
                                            }
                                        }

                                    }


                                    &.mat-calendar-body-cell {
                                        &:hover {
                                            .mat-calendar-body-cell-content {
                                                border: 1px solid #FFD3B3;
                                                background-color: #FFF0E6;
                                                border-radius: 4px;
                                            }
                                        }

                                        &.mat-calendar-body-active {
                                            &:hover {
                                                .mat-calendar-body-selected {
                                                    background-color: #FF6B00;
                                                    color: #FFF;
                                                    border-color: transparent;
                                                    font-family: "Archivo", sans-serif;


                                                }
                                            }
                                        }
                                    }
                                    &.booked-date{
                                        .mat-calendar-body-cell-content{
                                            color: #FF6B00;
                                          &::after{
                                            content: "";
                                            position: absolute;
                                            left: -14px;
                                            top: -14px;
                                            width: 24px;
                                            height: 24px;
                                            transform: rotate(-45deg);
                                            background-color: #FF6B00;
                                          }
                                        }
                                    }
                                }

                            }
                        }
                    }
                }
            }
        }
    }

}

//common checkbox style
.commoncheckbox {
    .mdc-form-field {
        .mdc-checkbox {
            padding: 0;

            .mat-mdc-checkbox-touch-target {
                display: none;
            }

            .mdc-checkbox__native-control {
                width: 100%;
                height: 100%;

            }

            .mdc-checkbox__ripple {
                display: none;
            }

            .mdc-checkbox__background {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                margin: 0;
                border: 1px solid #B9B9B9 !important;
                border-radius: 4px;
                background-color: #FAFAFA;
            }
        }

        label {
            color: #2D2D2D;
            font-weight: 400;
            font-family: 14px;
            line-height: 1.3;
            padding-left: 8px;
        }
    }


    &.mat-mdc-checkbox-checked {
        .mdc-checkbox {
            .mdc-checkbox__background {
                border-color: #FF6B00 !important;

                background-color: #FF6B00 !important;
            }
        }

        

    }
    &.resolvecheckbox {
        &.mat-mdc-checkbox-checked {
            .mdc-checkbox {
                .mdc-checkbox__background {
                    background-color: #E8E8E8 !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    svg{
                        width: 16px;
                        height: 16px;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50% , -50%);
                        path{
                            color: #2D2D2D;
                        }
                    }
                }
            }
    
            
    
        }
    }
    &.disablecheck{
        &.mat-mdc-checkbox-checked {
            .mdc-checkbox {
                .mdc-checkbox__background {
                    background-color: #BCE7FF !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: #BCE7FF !important;
                    svg{
                        width: 10px;
                        height: 10px;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50% , -50%);
                        path{
                            color: #2D2D2D;
                        }
                    }
                }
            }
            .mdc-label{
                color: #2D2D2D;

            }
        }
        .mdc-form-field{
            padding: 0;
           
        }
        margin: 8px;
        
        
    }
    
}

//customSelect style
.customSelect {
    .mat-mdc-text-field-wrapper {

        .mat-mdc-form-field-flex {
            border-radius: 8px;
            padding: 10px 16px;
            background-color: #FAFAFA;

            .mat-mdc-form-field-infix {
                min-height: 46px !important;

                .mat-mdc-select {
                    .mat-mdc-select-trigger {
                        .mat-mdc-select-arrow-wrapper {
                            display: none;
                        }

                        .mat-mdc-select-value {
                            span {

                                font-family: "Archivo", sans-serif;
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 1.2;
                                text-align: left;
                                color: #111928;

                            }
                        }
                    }
                }
            }
        }

    }
}


// table border remove
.table_SectionWrap {
    .ag-theme-quartz {
        .ag-root-wrapper {
            border: 0;

            .ag-root-wrapper-body {
                .ag-root {
                    .ag-header {
                        .ag-header-viewport {
                            .ag-header-container {
                                .ag-header-cell {
                                    .ag-header-cell-comp-wrapper {
                                        .ag-header-cell-text {
                                            color: #8B8B8B;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.customtell{
    display: flex !important;
    align-items: center;
    border-radius: 8px;
    max-height: 48px;
    height: 100%;
    width: 100%;
    
    .iti__country-container{
        min-width: 117px;
        border-right: 1px solid #E8E8E8;
        background-color: #F7F7F7;

        .iti__selected-country{
            position: relative;
            width: 100%;
            .iti__selected-country-primary{
                padding: 0;
                height: auto;
                .iti__flag{
                    height: 14px;
                    width: 20px;
                    background-size: 5762px 20px;
                }
                .iti__arrow{
                    border: 0;
                    width: 12px;
                    height: 7px;
                    position: absolute;
                    right: 25px;
                    &::before{
                        content: "";
                        position:absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50% , -50%);
                        z-index: 2;
                        background-image: url("../assets/icons/dropdown-icon.svg");
                        background-position: center;
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        width: 100%;
                        height: 100%;
                    }

                }
            }
            .iti__selected-dial-code{
                margin-left: 8px;
                color: #2D2D2D;
                font-size: 14px;
                font-family: "Archivo", sans-serif;
            }
        }
    }
    .common-tel-input{
        height: 100%;
        width: 100%;
        padding:12px 16px ;
        padding-left: 134px !important;
    }


}
// typing animation effect 
.dot-typing {
    position: relative;
    left: -9985px;
    width: 4px;
    height: 4px;
    border-radius: 8px;
    background-color: #737373;
    color: #737373;
    box-shadow: 9989px 0 0 0 #737373, 9999px 0 0 0 #737373, 10009px 0 0 0 #737373;
    animation: dot-typing 1.5s infinite linear;
}



  
  
  @keyframes dot-typing {
    0% {
      box-shadow: 9989px 0 0 0 #737373, 9999px 0 0 0 #737373, 10009px 0 0 0 #737373;
    }
    16.667% {
      box-shadow: 9989px -10px 0 0 #737373, 9999px 0 0 0 #737373, 10009px 0 0 0 #737373;
    }
    33.333% {
      box-shadow: 9989px 0 0 0 #737373, 9999px 0 0 0 #737373, 10009px 0 0 0 #737373;
    }
    50% {
      box-shadow: 9989px 0 0 0 #737373, 9999px -10px 0 0 #737373, 10009px 0 0 0 #737373;
    }
    66.667% {
      box-shadow: 9989px 0 0 0 #737373, 9999px 0 0 0 #737373, 10009px 0 0 0 #737373;
    }
    83.333% {
      box-shadow: 9989px 0 0 0 #737373, 9999px 0 0 0 #737373, 10009px -10px 0 0 #737373;
    }
    100% {
      box-shadow: 9989px 0 0 0 #737373, 9999px 0 0 0 #737373, 10009px 0 0 0 #737373;
    }
  }