/* ----------------------------------------------------------------------------------------------------- */
/*  @ Main Tailwind file for injecting utilities.
/* ----------------------------------------------------------------------------------------------------- */
@tailwind components;
@tailwind utilities;

@layer components {
    .all-\[unset\] {
        all: unset;
    }
}

:root {
    --back: rgba(22, 22, 22, 1);
    --blue-100: rgba(188, 231, 255, 1);
    --blue-200: rgba(144, 215, 255, 1);
    --blue-300: rgba(99, 199, 255, 1);
    --blue-400: rgba(54, 183, 255, 1);
    --blue-50: rgba(233, 247, 255, 1);
    --blue-500: rgba(32, 175, 255, 1);
    --blue-600: rgba(22, 164, 243, 1);
    --blue-700: rgba(13, 152, 230, 1);
    --blue-800: rgba(6, 145, 222, 1);
    --blue-900: rgba(0, 137, 214, 1);
    --desktop-gustavo-button-button-font-family: "Gustavo_trial", Helvetica;
    --desktop-gustavo-button-button-font-size: 16px;
    --desktop-gustavo-button-button-font-style: normal;
    --desktop-gustavo-button-button-font-weight: 500;
    --desktop-gustavo-button-button-letter-spacing: 0px;
    --desktop-gustavo-button-button-line-height: 150%;
    --desktop-gustavo-h1-h1-font-family: "Gustavo_trial", Helvetica;
    --desktop-gustavo-h1-h1-font-size: 36px;
    --desktop-gustavo-h1-h1-font-style: normal;
    --desktop-gustavo-h1-h1-font-weight: 500;
    --desktop-gustavo-h1-h1-letter-spacing: 0px;
    --desktop-gustavo-h1-h1-line-height: 129.99999523162842%;
    --desktop-gustavo-h2-h2-font-family: "Gustavo_trial", Helvetica;
    --desktop-gustavo-h2-h2-font-size: 32px;
    --desktop-gustavo-h2-h2-font-style: normal;
    --desktop-gustavo-h2-h2-font-weight: 500;
    --desktop-gustavo-h2-h2-letter-spacing: 0px;
    --desktop-gustavo-h2-h2-line-height: 129.99999523162842%;
    --desktop-gustavo-h3-h3-font-family: "Gustavo_trial", Helvetica;
    --desktop-gustavo-h3-h3-font-size: 28px;
    --desktop-gustavo-h3-h3-font-style: normal;
    --desktop-gustavo-h3-h3-font-weight: 500;
    --desktop-gustavo-h3-h3-letter-spacing: 0px;
    --desktop-gustavo-h3-h3-line-height: 129.99999523162842%;
    --desktop-gustavo-title-title-1-font-family: "Gustavo_trial", Helvetica;
    --desktop-gustavo-title-title-1-font-size: 24px;
    --desktop-gustavo-title-title-1-font-style: normal;
    --desktop-gustavo-title-title-1-font-weight: 500;
    --desktop-gustavo-title-title-1-letter-spacing: 0px;
    --desktop-gustavo-title-title-1-line-height: 150%;
    --desktop-gustavo-title-title-2-font-family: "Gustavo_trial", Helvetica;
    --desktop-gustavo-title-title-2-font-size: 20px;
    --desktop-gustavo-title-title-2-font-style: normal;
    --desktop-gustavo-title-title-2-font-weight: 500;
    --desktop-gustavo-title-title-2-letter-spacing: 0px;
    --desktop-gustavo-title-title-2-line-height: 150%;
    --desktop-gustavo-title-title-3-font-family: "Gustavo_trial", Helvetica;
    --desktop-gustavo-title-title-3-font-size: 18px;
    --desktop-gustavo-title-title-3-font-style: normal;
    --desktop-gustavo-title-title-3-font-weight: 500;
    --desktop-gustavo-title-title-3-letter-spacing: 0px;
    --desktop-gustavo-title-title-3-line-height: 150%;
    --desktop-host-grotesk-body-l-bold-font-family: "Host Grotesk", Helvetica;
    --desktop-host-grotesk-body-l-bold-font-size: 18px;
    --desktop-host-grotesk-body-l-bold-font-style: normal;
    --desktop-host-grotesk-body-l-bold-font-weight: 700;
    --desktop-host-grotesk-body-l-bold-letter-spacing: 0px;
    --desktop-host-grotesk-body-l-bold-line-height: 150%;
    --desktop-host-grotesk-body-l-medium-font-family: "Host Grotesk", Helvetica;
    --desktop-host-grotesk-body-l-medium-font-size: 18px;
    --desktop-host-grotesk-body-l-medium-font-style: normal;
    --desktop-host-grotesk-body-l-medium-font-weight: 500;
    --desktop-host-grotesk-body-l-medium-letter-spacing: 0px;
    --desktop-host-grotesk-body-l-medium-line-height: 150%;
    --desktop-host-grotesk-body-m-bold-font-family: "Host Grotesk", Helvetica;
    --desktop-host-grotesk-body-m-bold-font-size: 16px;
    --desktop-host-grotesk-body-m-bold-font-style: normal;
    --desktop-host-grotesk-body-m-bold-font-weight: 700;
    --desktop-host-grotesk-body-m-bold-letter-spacing: 0px;
    --desktop-host-grotesk-body-m-bold-line-height: 150%;
    --desktop-host-grotesk-body-m-medium-font-family: "Host Grotesk", Helvetica;
    --desktop-host-grotesk-body-m-medium-font-size: 16px;
    --desktop-host-grotesk-body-m-medium-font-style: normal;
    --desktop-host-grotesk-body-m-medium-font-weight: 500;
    --desktop-host-grotesk-body-m-medium-letter-spacing: 0px;
    --desktop-host-grotesk-body-m-medium-line-height: 150%;
    --desktop-host-grotesk-body-m-regular-font-family: "Host Grotesk", Helvetica;
    --desktop-host-grotesk-body-m-regular-font-size: 16px;
    --desktop-host-grotesk-body-m-regular-font-style: normal;
    --desktop-host-grotesk-body-m-regular-font-weight: 400;
    --desktop-host-grotesk-body-m-regular-letter-spacing: 0px;
    --desktop-host-grotesk-body-m-regular-line-height: 150%;
    --desktop-host-grotesk-body-s-bold-font-family: "Host Grotesk", Helvetica;
    --desktop-host-grotesk-body-s-bold-font-size: 14px;
    --desktop-host-grotesk-body-s-bold-font-style: normal;
    --desktop-host-grotesk-body-s-bold-font-weight: 700;
    --desktop-host-grotesk-body-s-bold-letter-spacing: 0px;
    --desktop-host-grotesk-body-s-bold-line-height: 150%;
    --desktop-host-grotesk-body-s-medium-font-family: "Host Grotesk", Helvetica;
    --desktop-host-grotesk-body-s-medium-font-size: 14px;
    --desktop-host-grotesk-body-s-medium-font-style: normal;
    --desktop-host-grotesk-body-s-medium-font-weight: 500;
    --desktop-host-grotesk-body-s-medium-letter-spacing: 0px;
    --desktop-host-grotesk-body-s-medium-line-height: 150%;
    --desktop-host-grotesk-body-s-regular-font-family: "Host Grotesk", Helvetica;
    --desktop-host-grotesk-body-s-regular-font-size: 14px;
    --desktop-host-grotesk-body-s-regular-font-style: normal;
    --desktop-host-grotesk-body-s-regular-font-weight: 400;
    --desktop-host-grotesk-body-s-regular-letter-spacing: 0px;
    --desktop-host-grotesk-body-s-regular-line-height: 150%;
    --desktop-host-grotesk-cations-bold-font-family: "Host Grotesk", Helvetica;
    --desktop-host-grotesk-cations-bold-font-size: 12px;
    --desktop-host-grotesk-cations-bold-font-style: normal;
    --desktop-host-grotesk-cations-bold-font-weight: 700;
    --desktop-host-grotesk-cations-bold-letter-spacing: 0px;
    --desktop-host-grotesk-cations-bold-line-height: 150%;
    --desktop-host-grotesk-cations-medium-font-family: "Host Grotesk", Helvetica;
    --desktop-host-grotesk-cations-medium-font-size: 12px;
    --desktop-host-grotesk-cations-medium-font-style: normal;
    --desktop-host-grotesk-cations-medium-font-weight: 400;
    --desktop-host-grotesk-cations-medium-letter-spacing: 0px;
    --desktop-host-grotesk-cations-medium-line-height: 150%;
    --desktop-host-grotesk-cations-regular-font-family: "Host Grotesk", Helvetica;
    --desktop-host-grotesk-cations-regular-font-size: 12px;
    --desktop-host-grotesk-cations-regular-font-style: normal;
    --desktop-host-grotesk-cations-regular-font-weight: 400;
    --desktop-host-grotesk-cations-regular-letter-spacing: 0px;
    --desktop-host-grotesk-cations-regular-line-height: 150%;
    --desktop-host-grotesk-cations-uppercase-font-family: "Host Grotesk", Helvetica;
    --desktop-host-grotesk-cations-uppercase-font-size: 12px;
    --desktop-host-grotesk-cations-uppercase-font-style: normal;
    --desktop-host-grotesk-cations-uppercase-font-weight: 700;
    --desktop-host-grotesk-cations-uppercase-letter-spacing: 0px;
    --desktop-host-grotesk-cations-uppercase-line-height: 150%;
    --gray-100: rgba(247, 247, 247, 1);
    --gray-200: rgba(232, 232, 232, 1);
    --gray-300: rgba(208, 208, 208, 1);
    --gray-400: rgba(185, 185, 185, 1);
    --gray-50: rgba(250, 250, 250, 1);
    --gray-500: rgba(139, 139, 139, 1);
    --gray-600: rgba(115, 115, 115, 1);
    --gray-700: rgba(92, 92, 92, 1);
    --gray-800: rgba(69, 69, 69, 1);
    --gray-900: rgba(45, 45, 45, 1);
    --green-100: rgba(222, 247, 236, 1);
    --green-200: rgba(188, 240, 218, 1);
    --green-300: rgba(132, 225, 188, 1);
    --green-400: rgba(49, 196, 141, 1);
    --green-50: rgba(243, 250, 247, 1);
    --green-500: rgba(14, 159, 110, 1);
    --green-600: rgba(5, 122, 85, 1);
    --green-700: rgba(4, 108, 78, 1);
    --green-800: rgba(3, 84, 63, 1);
    --green-900: rgba(1, 71, 55, 1);
    --pink-100: rgba(252, 232, 243, 1);
    --pink-200: rgba(250, 209, 232, 1);
    --pink-300: rgba(248, 180, 217, 1);
    --pink-400: rgba(241, 126, 184, 1);
    --pink-50: rgba(253, 242, 248, 1);
    --pink-500: rgba(231, 70, 148, 1);
    --pink-600: rgba(214, 31, 105, 1);
    --pink-700: rgba(191, 18, 93, 1);
    --pink-800: rgba(153, 21, 75, 1);
    --pink-900: rgba(117, 26, 61, 1);
    --primary-100: rgba(188, 231, 255, 1);
    --primary-200: rgba(144, 215, 255, 1);
    --primary-300: rgba(99, 199, 255, 1);
    --primary-400: rgba(54, 183, 255, 1);
    --primary-50: rgba(233, 247, 255, 1);
    --primary-500: rgba(32, 175, 255, 1);
    --primary-600: rgba(22, 164, 243, 1);
    --primary-700: rgba(13, 152, 230, 1);
    --primary-800: rgba(6, 145, 222, 1);
    --primary-900: rgba(0, 137, 214, 1);
    --purple-100: rgba(237, 235, 254, 1);
    --purple-200: rgba(220, 215, 254, 1);
    --purple-300: rgba(202, 191, 253, 1);
    --purple-400: rgba(172, 148, 250, 1);
    --purple-50: rgba(246, 245, 255, 1);
    --purple-500: rgba(126, 58, 242, 1);
    --purple-700: rgba(108, 43, 217, 1);
    --purple-800: rgba(85, 33, 181, 1);
    --purple-900: rgba(74, 29, 150, 1);
    --red-100: rgba(253, 232, 232, 1);
    --red-200: rgba(251, 213, 213, 1);
    --red-300: rgba(248, 180, 180, 1);
    --red-400: rgba(249, 128, 128, 1);
    --red-50: rgba(253, 242, 242, 1);
    --red-500: rgba(240, 82, 82, 1);
    --red-600: rgba(224, 36, 36, 1);
    --red-700: rgba(200, 30, 30, 1);
    --red-800: rgba(155, 28, 28, 1);
    --red-900: rgba(119, 29, 29, 1);
    --secondary-100: rgba(255, 225, 204, 1);
    --secondary-200: rgba(255, 211, 179, 1);
    --secondary-300: rgba(255, 196, 153, 1);
    --secondary-400: rgba(255, 181, 128, 1);
    --secondary-50: rgba(255, 240, 230, 1);
    --secondary-500: rgba(255, 166, 102, 1);
    --secondary-600: rgba(255, 151, 77, 1);
    --secondary-700: rgba(255, 137, 51, 1);
    --secondary-800: rgba(255, 122, 26, 1);
    --secondary-900: rgba(255, 107, 0, 1);
    --shadow-lg: 0px 1px 24px 0px rgba(31, 42, 55, 0.08);
    --shadow-md: 0px 4px 12px -2px rgba(139, 139, 139, 0.08), 0px 2px 8px -1px rgba(139, 139, 139, 0.04);
    --shadow-sm: 0px 1px 2px 0px rgba(45, 45, 45, 0.08);
    --white: rgba(255, 255, 255, 1);
    --yellow-100: rgba(253, 246, 178, 1);
    --yellow-200: rgba(252, 233, 106, 1);
    --yellow-300: rgba(250, 202, 21, 1);
    --yellow-400: rgba(227, 160, 8, 1);
    --yellow-50: rgba(253, 253, 234, 1);
    --yellow-500: rgba(194, 120, 3, 1);
    --yellow-600: rgba(159, 88, 10, 1);
    --yellow-700: rgba(142, 75, 16, 1);
    --yellow-800: rgba(114, 59, 19, 1);
    --yellow-900: rgba(99, 49, 18, 1);
}

